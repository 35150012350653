.footer {
	color: white;
	padding: 10px;
	font-size: 14px;
  bottom: 0px;
  width: 100%;
  text-align: right;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.footer-container {
  width: 100%;
  background: url('/public/images/bg.png');
  margin-top: auto;
  flex: 0 0 5px;
}

.footer a {
  padding-left: 15px;
  color: #edb059;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.MuiSvgIcon-fontSizeLarge {
  font-size: 30px !important;
}

.footer a:hover {
  color: #666;
}
