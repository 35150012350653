.header
{
	width: 100%;
	min-height: 100px;
  border-bottom: 1px solid #eee;
  background-color: white;
  position: fixed;
  z-index: 5;
}

.nav
{
	list-style: none;
	padding: 0;
  text-align: center;
  background-color: white;
  margin-top: 10px;
  margin-bottom: 5px;
}

.nav-active {
  color: #edb059 !important;
}

.nav .nav--link
{
	display: inline-block;
	margin-left: 25px;
}

.nav li
{
	float: left;
	text-decoration: none;
    font-size: 14px;
    font-weight: 501;
}

.nav a {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
}

.nav.is_scrolled {
  font-size: 20em;
}

.nav a:hover {
  opacity: 0.7;
}

.scroll {
  transition: all 0.4s ease-in-out;
}

.scroll img {
  max-width: 55px;
  transition: all 0.4s ease-in-out;
}

.burger-wrapper, .hamburger {
	display: none;
}

.burger-wrapper {
  cursor: pointer;
  margin: auto;
  width: 40px;
  height: 40px;

  position: absolute;
  left: 15px;
  top: 30px;
}
.burger-wrapper .hamburger {
  background: black;
  width: 100%;
  height: 5px;
  position: relative;
  transition: background 10ms 300ms ease;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
}
.burger-wrapper .hamburger:before, .burger-wrapper .hamburger:after {
  transition: top 300ms 350ms ease, -webkit-transform 300ms 50ms ease;
  transition: top 300ms 350ms ease, transform 300ms 50ms ease;
  transition: top 300ms 350ms ease, transform 300ms 50ms ease, -webkit-transform 300ms 50ms ease;
  position: absolute;
  background: black;
  width: 40px;
  height: 5px;
  content: '';
}
.burger-wrapper .hamburger:before {
  top: -15px;
}
.burger-wrapper .hamburger:after {
  top: 15px;
}

.menu-active .burger-wrapper .hamburger {
  background: transparent;
}
.menu-active .burger-wrapper .hamburger:after, .menu-active .burger-wrapper .hamburger:before {
  transition: top 300ms 50ms ease, -webkit-transform 300ms 350ms ease;
  transition: top 300ms 50ms ease, transform 300ms 350ms ease;
  transition: top 300ms 50ms ease, transform 300ms 350ms ease, -webkit-transform 300ms 350ms ease;
  top: 0;
}
.menu-active .burger-wrapper .hamburger:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.menu-active .burger-wrapper .hamburger:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}


@media only screen and (max-width: 600px) {
	.nav
	{
		position: relative;
		z-index: 0;
		margin-top: 120px;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
	}
	.nav .nav--link
	{
		float: none;
		display: block;
		margin: 15px;
	}

	.burger-wrapper, .hamburger {
		display: block;
	}
  .header {
    position: relative;
  }
}
.show {
  display: block !important;
}

.header-icon {
  margin-left: auto;
  margin-right: auto;
  display: table-caption;
  max-width: 70px;
  padding-top: 10px;
}

.header-icon-link {
  width: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
