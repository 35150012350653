.ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
  color: 'red';
}

/* Snow Theme */
.ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.ql-editor {
  min-height: 18em;
}

.ql-font .ql-picker .ql-expanded {
  width: 150px;
}

.ql-snow .ql-picker.ql-font {
  width: 150px !important;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="arial"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="arial"]::before {
  content: "Arial";
  font-family: "Arial";
}
.ql-font-arial {
  font-family: "Arial";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="times-new-roman"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="times-new-roman"]::before {
  content: "Times New Roman";
  font-family: "Times New Roman";
}
.ql-font-times-new-roman {
  font-family: "Times New Roman";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="helvetica"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="helvetica"]::before {
  content: "Helvetica";
  font-family: "Helvetica";
}
.ql-font-helvetica {
  font-family: "Helvetica";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="roboto"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="roboto"]::before {
  content: "Roboto";
  font-family: "Roboto";
}
.ql-font-roboto {
  font-family: "Roboto";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="open-sans"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="open-sans"]::before {
  content: "Open Sans";
  font-family: "Open Sans";
}
.ql-font-open-sans {
  font-family: "Open Sans";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="montserrat"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="montserrat"]::before {
  content: "Montserrat";
  font-family: "Montserrat";
}
.ql-font-montserrat {
  font-family: "Montserrat";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="raleway"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="raleway"]::before {
  content: "Raleway";
  font-family: "Raleway";
}
.ql-font-raleway {
  font-family: "Raleway";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="roboto-mono"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="roboto-mono"]::before {
  content: "Roboto Mono";
  font-family: "Roboto Mono";
}
.ql-font-roboto-mono {
  font-family: "Roboto Mono";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="lato"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="lato"]::before {
  content: "Lato, sans-serif";
  font-family: "Lato, sans-serif";
}
.ql-font-roboto-mono {
  font-family: "Lato, sans-serif";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10px";
  font-size: 10px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before {
  content: "10px";
}

.ql-size-10px {
  font-size: 10px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12px";
  font-size: 12px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before {
  content: "12px";
}

.ql-size-12px {
  font-size: 12px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14px";
  font-size: 14px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before {
  content: "14px";
}

.ql-size-14px {
  font-size: 14px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16px";
  font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before {
  content: "16px";
}

.ql-size-16px {
  font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18px";
  font-size: 18px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before {
  content: "18px";
}

.ql-size-18px {
  font-size: 18px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20px";
  font-size: 20px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before {
  content: "20px";
}

.ql-size-20px {
  font-size: 20px;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}
