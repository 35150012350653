.lesson_widget
{
	background: url('/public/images/bg.png');

	color: white;
	border-radius: 7px;

	width: 240px;
	padding: 15px;
  min-height: 550px;
}

.lesson_widget__title
{
	border-bottom: 2px dotted white;
	padding-bottom: 5px;

	font-weight: normal;
}

.lesson_widget__details
{
	list-style: none;

	padding: 0;
}

.lesson_widget__details li
{
	margin-top: 10px;

	white-space: pre-line;
}

.lesson_widget__divider
{
	border: 1px dotted white;
}
@media screen and (max-width: 600px)
{
	.lesson_widget
	{
		width: 100%;
    min-height: 0%;
	}
}
