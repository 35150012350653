.popup {
	width: 70%;
	/* max-width: 500px; */
	max-height: 90vh;
	overflow: scroll;
	border: 1px solid black;
	background-color: white;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	box-shadow: gray 0px 0px 100px;
	border-radius: 12px;
	padding: 10px;
  z-index: 20;
}

.confirmFeedback > p {
  margin: 0px;
}
