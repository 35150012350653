.lessonreport__input {
	width: 100%;
	height: 200px;
	border-radius: 7px;
	padding: 10px;
}

.feedback {
  background-color: #eeeeee;
  padding: 10px 10px 10px 10px;
  border-radius: 5px 5px 5px 5px;
}

.feedback p {
  margin: 0px;
}

.lessonreport__select {
	border-radius: 7px;
	padding: 10px;
	background: none;
}

.error {
	color: red;
	font-weight: bold;
  margin-top: 15px;
}

.lessonResources {
  display: table-cell;
  flex: 44%
}

.lessonInfo {
  flex: 100%
}

.lessonDetails {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 800px) {
  .lessonDetails {
    flex-direction: column;
  }
}

.zoom_login_text {
  font-size: 12px;
  color: red;
}

.onclick_url {
  color: #f90;
  cursor: pointer;
  background: none;
	border: none;
	padding: 0;
	font: inherit;
	outline: inherit;
}
