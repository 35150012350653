.changepassword {
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  display: grid;
}

.changepassword .btn {
  width: 100%;
  padding: 10px;
  margin: 10px 0px 0px 0px;
}
