.lessonComment_container {
  margin-top: 10px;
}

.lessonComment_element {
  margin-top: 10px;
  margin-bottom: 10px;
}

.lessonComment_description {
  font-weight: bold;
  margin: 0px 15px 15px 15px;
}
