.login__input {
	padding: 10px;
  margin-top: 20px;
}

.btn--forgot {
	font-size: 12px;
}

.login_container {
 width: 300px;
 text-align: center;
}

.login {
  text-align: center;
}

.login_outer_container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  margin-bottom: 50px;
}

.login_border {
border: 3px solid gray;
padding: 40px 50px 25px 50px;
 border-radius: 13px;
 text-align: center;
 border-color: #BEBEBE;
}

.login-btn {
  margin-top: 30px;
  height: 40px;
  width: 100%;
  padding: 0;
  margin: 15px 0px 0px 0px;

}

.MuiFormControl-root {
  width: 100% !important;
}
.MuiTextField-root {
  width: 100% !important;
}

.forgotten_password {
  font-size: 12px;
  margin-top: 20px;
}

.onclick_url {
  color: #f90;
  cursor: pointer;
  background: none;
	border: none;
	padding: 0;
	font: inherit;
	outline: inherit;
}
