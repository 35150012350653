.float_left{
	float:left;
}
*{
	word-wrap:break-word;
}
.dash_item{
	list-style: none;
	border-bottom:1px dotted rgba(0,0,0,1);
	margin-bottom:10px;
  margin-top: 10px;
	/* height:80px; */
	font-size: 18px;
}
.dash_item p{
	color:rgb(0,0,0);
	font-size:14px;
}

.dash_item:hover {
	color: rgb(210, 180, 30);
}

.dash_item:hover p {
	color: rgb(100, 100, 100);
}
#content_container_dash_item{
	max-width:550px;
	width: 90%;
	padding-left: 20px;
  font-size: 1.2em;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}
#content_container_dash_item p {
  transition: all 0.4s ease-in-out;
}
#img_container_dash_item{
	max-width:75px;
	width: 10%;
}
#img_container_dash_item img{
	width: 100%;
	height: 100%;
}
.dash__list {
	padding: 0;

	width: calc(100% - 260px);
	margin-right: 20px;
}

@media screen and (max-width: 600px)
{
	.dash__list {
		width: 100%;
		margin: 0;
	}
  #img_container_dash_item {
    width: 21%;
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
  .dash_item {
    text-align: center;
  }
  .nav {
    display: none;
    margin-top: 0px;
  }
}

.practice_folder {
  font-size: 14px;
  margin-right: 40px;
  display: inline-block;
}

@media only screen and (max-width: 600px) {
  .practice_folder {
    margin-right: 0px;
    display: block;
    margin-bottom: 10px;
  }
}
