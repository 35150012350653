body
{
  height: 100%;
  /* background: url('/images/bg.png'); */
}

html {
  height: 100%;
}

#root {
  height: 100%;
}

* {
	box-sizing: border-box;
}

a {
	color: #FF9900;
	text-decoration: none;
}

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input { /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select { /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}



a:hover {
	text-decoration: underline;
}

.App {
	position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.container
{
	min-height: 500px;
	padding: 15px;
	background-color: white;
	position: relative;

	width: 100%;
	/* transition: top 200ms; */
	left: 0;
  margin-top: 160px;
}
@media screen and (max-width: 600px) {
  .container {
    margin-top: 0px;
  }
}

.container__inner
{
	max-width: 1200px;
	margin: 0 auto;
}

@media screen and (max-width: 600px) {
	.menu-active + .container
	{
		/* left: 200px; */
	}
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.btn {
	padding: 10px;
	border-radius: 7px;
	background: #F90;
	color: white;
	font-weight: bold;
	border-color: #F90;
	border-style: solid;
	cursor: pointer;
	transition: all 200ms;
	outline: none;
	margin: 10px;
}

.btn:hover{
	background: white;
	color: #F90;
	font-weight: bold;
	border-color: #F90;
}

.banner{
	display: block;
    padding: 1px 10px 1px 10px;
    border-radius: 5px 5px 5px 5px;
}

.banner {
  color: black;
}

.banner:hover {
  text-decoration: none;
}

